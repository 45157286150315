import { DEV_ORIGIN } from "@assets/const/building";
import { notification } from "antd";
import { addDays } from "date-fns";
import moment from "moment";

function requiredZodErr(fieldLabel) {
  const msg = `${fieldLabel} is required`;
  return {
    required_error: msg,
    message: msg
  };
}

function upsertFieldsNotFoundOrEmpty(original, replacer) {
  for (const [key, value] of Object.entries(replacer)) {
    if (!original[key]) {
      original[key] = value;
    }
  }
  return original;
}

function maskInput(value, showCount = 3, mask = "*") {
  if (!value || showCount > value.length) {
    return value;
  }
  return `${value.substr(0, showCount)}${mask.repeat(
    value.length - showCount
  )}`;
}

function validateSpecialInputFields(fields) {
  function validateFedId(value) {
    const regex = new RegExp(/^\d{2}-\d{7}$/);
    return regex.test(value)
      ? ""
      : "The Federal ID field must follow the format 12-3456789";
  }
  function validateTaxId(value) {
    const regex = new RegExp(/^\d{2}-\d{7}$/);
    return regex.test(value)
      ? ""
      : "The Tax ID field must follow the format 12-3456789";
  }
  function validateVinNumber(value) {
    const regex = /^[A-HJ-NPR-Z0-9]{17}$/;
    return regex.test(value)
      ? ""
      : "VIN number must be 17 - all cap - alphanumeric characters (except I, O, and Q).";
  }

  for (const [key, value] of Object.entries(fields)) {
    var err = "";
    switch (key) {
      case "federalId":
        err = validateFedId(value);
        break;
      case "tax_id":
        err = validateTaxId(value);
        break;
      case "vin":
        err = validateVinNumber(value);
        break;
    }
    if (err) return err;
  }
  return "";
}

function validateDate(dateStr) {
  if (!dateStr) {
    return true;
  }
  try {
    const date = new Date(dateStr);
    if (date == "Invalid Date") {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    return false;
  }
}

function notify(title, description, type, placement) {
  if (type === "success") {
    notification.success({
      message: title,
      description: description,
      duration: 5,
      placement: placement || "bottomRight"
    });
  } else {
    notification.error({
      message: title,
      description: description,
      duration: 10,
      placement: placement || "bottomRight"
    });
  }
}

function extractAPIErrMsg(error, defaultMsg) {
  return error.response?.data?.message || error.message || defaultMsg;
}

function toUSD(value) {
  return `$${(value === undefined ? "" : parseInt(value)).toLocaleString(
    "en-US"
  )}`;
}

function getInsuranceData(entity) {
  if (entity.isInsureDiff) {
    return entity.insuranceData || {};
  }

  return entity;
}

function getIsExpired(dateItem, day) {
  if (day == 0) {
    if (moment(dateItem).isValid && moment().clone() > moment(dateItem)) {
      return true;
    } else {
      return false;
    }
  }
  if (
    moment(dateItem).isValid &&
    moment().clone().add(day, "days") > moment(dateItem) &&
    moment(dateItem) > moment().clone()
  ) {
    return true;
  } else {
    return false;
  }
}

function getPolicyExpired(item, day) {
  if (
    getIsExpired(item?.finalData?.commercial_general_liability_expire_date, day)
  ) {
    return true;
  }
  if (getIsExpired(item?.finalData?.automotive_liability_expire_date, day)) {
    return true;
  }
  if (getIsExpired(item?.finalData?.workers_compensation_expire_date, day)) {
    return true;
  }
  if (getIsExpired(item?.finalData?.umbrella_subrogration_expire_date, day)) {
    return true;
  }
  if (getIsExpired(item?.finalData?.crime_expire_date, day)) {
    return true;
  }
  if (getIsExpired(item?.finalData?.garage_expire_date, day)) {
    return true;
  }
  if (getIsExpired(item?.finalData?.epli_expire_date, day)) {
    return true;
  }
  if (getIsExpired(item?.finalData?.cyber_expire_date, day)) {
    return true;
  }
  if (getIsExpired(item?.finalData?.pro_liab_expire_date, day)) {
    return true;
  }
  if (getIsExpired(item?.finalData?.pol_liab_expire_date, day)) {
    return true;
  }
  if (getIsExpired(item?.finalData?.im_expire_date, day)) {
    return true;
  }
  if (getIsExpired(item?.finalData?.pro_ins_expire_date, day)) {
    return true;
  }
  return false;
}

function getRenewalPolicyStatus(contact, requirement) {
  if (!requirement) {
    return "";
  }
  const compFuture = addDays(new Date(), 15),
    compNow = new Date();
  let totalCount = 0,
    activeCount = 0,
    isExpiring = false,
    isLapsed = false;

  if (requirement.commercial_general_liability_required) {
    totalCount++;
    if (contact?.finalData?.commercial_general_liability_expire_date) {
      activeCount++;
    }
    if (
      contact?.finalData?.commercial_general_liability_expire_date &&
      new Date(contact?.finalData?.commercial_general_liability_expire_date) <
        compNow
    ) {
      isLapsed = true;
    }

    if (
      contact?.finalData?.commercial_general_liability_expire_date &&
      new Date(contact?.finalData?.commercial_general_liability_expire_date) <
        compFuture
    ) {
      isExpiring = true;
    }
  }

  if (requirement.automotive_liability_required) {
    totalCount++;
    if (contact?.finalData?.automotive_liability_expire_date) {
      activeCount++;
    }
    if (
      contact?.finalData?.automotive_liability_expire_date &&
      new Date(contact?.finalData?.automotive_liability_expire_date) < compNow
    ) {
      isLapsed = true;
    }
    if (
      contact?.finalData?.automotive_liability_expire_date &&
      new Date(contact?.finalData?.automotive_liability_expire_date) <
        compFuture
    ) {
      isExpiring = true;
    }
  }

  if (requirement.workers_compensation_required) {
    totalCount++;
    if (contact?.finalData?.workers_compensation_expire_date) {
      activeCount++;
    }
    if (
      contact?.finalData?.workers_compensation_expire_date &&
      new Date(contact?.finalData?.workers_compensation_expire_date) < compNow
    ) {
      isLapsed = true;
    }
    if (
      contact?.finalData?.workers_compensation_expire_date &&
      new Date(contact?.finalData?.workers_compensation_expire_date) <
        compFuture
    ) {
      isExpiring = true;
    }
  }

  if (requirement.umbrella_subrogration_required) {
    totalCount++;
    if (contact?.finalData?.umbrella_subrogration_expire_date) {
      activeCount++;
    }
    if (
      contact?.finalData?.umbrella_subrogration_expire_date &&
      new Date(contact?.finalData?.umbrella_subrogration_expire_date) < compNow
    ) {
      isLapsed = true;
    }
    if (
      contact?.finalData?.umbrella_subrogration_expire_date &&
      new Date(contact?.finalData?.umbrella_subrogration_expire_date) <
        compFuture
    ) {
      isExpiring = true;
    }
  }

  if (requirement.crime_required) {
    totalCount++;
    if (contact?.finalData?.crime_expire_date) {
      activeCount++;
    }
    if (
      contact?.finalData?.crime_expire_date &&
      new Date(contact?.finalData?.crime_expire_date) < compNow
    ) {
      isLapsed = true;
    }

    if (
      contact?.finalData?.crime_expire_date &&
      new Date(contact?.finalData?.crime_expire_date) < compFuture
    ) {
      isExpiring = true;
    }
  }

  if (requirement.garage_required) {
    totalCount++;
    if (contact?.finalData?.garage_expire_date) {
      activeCount++;
    }
    if (
      contact?.finalData?.garage_expire_date &&
      new Date(contact?.finalData?.garage_expire_date) < compNow
    ) {
      isLapsed = true;
    }

    if (
      contact?.finalData?.garage_expire_date &&
      new Date(contact?.finalData?.garage_expire_date) < compFuture
    ) {
      isExpiring = true;
    }
  }

  if (requirement.epli_required) {
    totalCount++;
    if (contact?.finalData?.epli_expire_date) {
      activeCount++;
    }
    if (
      contact?.finalData?.epli_expire_date &&
      new Date(contact?.finalData?.epli_expire_date) < compNow
    ) {
      isLapsed = true;
    }

    if (
      contact?.finalData?.epli_expire_date &&
      new Date(contact?.finalData?.epli_expire_date) < compFuture
    ) {
      isExpiring = true;
    }
  }

  if (requirement.cyber_required) {
    totalCount++;
    if (contact?.finalData?.cyber_expire_date) {
      activeCount++;
    }
    if (
      contact?.finalData?.cyber_expire_date &&
      new Date(contact?.finalData?.cyber_expire_date) < compNow
    ) {
      isLapsed = true;
    }

    if (
      contact?.finalData?.cyber_expire_date &&
      new Date(contact?.finalData?.cyber_expire_date) < compFuture
    ) {
      isExpiring = true;
    }
  }

  if (requirement.pro_liab_required) {
    totalCount++;
    if (contact?.finalData?.pro_liab_expire_date) {
      activeCount++;
    }
    if (
      contact?.finalData?.pro_liab_expire_date &&
      new Date(contact?.finalData?.pro_liab_expire_date) < compNow
    ) {
      isLapsed = true;
    }

    if (
      contact?.finalData?.pro_liab_expire_date &&
      new Date(contact?.finalData?.pro_liab_expire_date) < compFuture
    ) {
      isExpiring = true;
    }
  }

  if (requirement.pol_liab_required) {
    totalCount++;
    if (contact?.finalData?.pol_liab_expire_date) {
      activeCount++;
    }
    if (
      contact?.finalData?.pol_liab_expire_date &&
      new Date(contact?.finalData?.pol_liab_expire_date) < compNow
    ) {
      isLapsed = true;
    }

    if (
      contact?.finalData?.pol_liab_expire_date &&
      new Date(contact?.finalData?.pol_liab_expire_date) < compFuture
    ) {
      isExpiring = true;
    }
  }

  if (requirement.im_required) {
    totalCount++;
    if (contact?.finalData?.im_expire_date) {
      activeCount++;
    }
    if (
      contact?.finalData?.im_expire_date &&
      new Date(contact?.finalData?.im_expire_date) < compNow
    ) {
      isLapsed = true;
    }

    if (
      contact?.finalData?.im_expire_date &&
      new Date(contact?.finalData?.im_expire_date) < compFuture
    ) {
      isExpiring = true;
    }
  }

  if (requirement.pro_ins_required) {
    totalCount++;
    if (contact?.finalData?.pro_ins_expire_date) {
      activeCount++;
    }
    if (
      contact?.finalData?.pro_ins_expire_date &&
      new Date(contact?.finalData?.pro_ins_expire_date) < compNow
    ) {
      isLapsed = true;
    }

    if (
      contact?.finalData?.pro_ins_expire_date &&
      new Date(contact?.finalData?.pro_ins_expire_date) < compFuture
    ) {
      isExpiring = true;
    }
  }

  if (isLapsed) {
    return "Lapsed";
  }

  if (isExpiring) {
    return "Expiring";
  }

  if (totalCount > 0 && totalCount === activeCount) {
    return "Active";
  }

  return "Not-Verified";
}

function getItemRenewalPolicyStatus(contactData, requirementData) {
  let isDate = false;
  if (!requirementData) {
    return "";
  }
  const compFuture = addDays(new Date(), 15),
    compNow = new Date();

  if (requirementData) {
    if (contactData) {
      isDate = true;
    }
    if (contactData && new Date(contactData) < compNow) {
      return "Lapsed";
    }

    if (contactData && new Date(contactData) < compFuture) {
      return "Expiring";
    }
  }

  if (isDate) {
    return "Active";
  }

  return "Not-Verified";
}

function getIsDevEnv(url) {
  return DEV_ORIGIN.includes(url);
}

function getValidCode(code) {
  if (!code) {
    return "";
  }

  return code.split(" ").join("");
}

function filterValue(value) {
  if (!value || value === "") {
    return undefined;
  }

  return value;
}

function getPrimaryContact(franchiseeData) {
  if (franchiseeData?.isInsureDiff) {
    return {
      firstName: franchiseeData?.insuranceData?.first_name,
      lastName: franchiseeData?.insuranceData?.last_name,
      email: franchiseeData?.insuranceData?.email,
      telephone: franchiseeData?.insuranceData?.telephone,
      isPrimary: true,
      isActive: true
    };
  } else if (franchiseeData?.email) {
    return {
      firstName: franchiseeData?.first_name,
      lastName: franchiseeData?.last_name,
      email: franchiseeData?.email,
      telephone: franchiseeData?.telephone,
      isPrimary: true,
      isActive: true
    };
  } else {
    return null;
  }
}

async function batchRun(callbacks, batchSizeIncoming) {
  const batchSize = batchSizeIncoming || 10;
  const batches = callbacks.reduce((acc, callback) => {
    if (acc.length === 0) {
      acc.push([]);
    } // looks like: [[]]

    const lastBatch = acc[acc.length - 1]; // looks like: []
    if (lastBatch.length < batchSize) {
      lastBatch.push(callback);
    } else {
      acc.push([callback]);
    }

    return acc;
  }, []);

  for (const batch of batches) {
    await Promise.all(batch.map((callback) => callback()));
  }
}

function validateEmail(email) {
  return email.match(
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
}

function formatNumber(value) {
  if (value) {
    // Remove any non-numeric characters (in case the user enters dashes or spaces)
    const digits = value.replace(/\D/g, "");

    // Check if the number has the right amount of digits
    if (digits.length === 10) {
      // Format number to (xxx) xxx-xxxx
      return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6)}`;
    }
  }

  // Return the original value if it doesn't meet the conditions for formatting
  return value;
}

function validateNumber(value) {
  if (value) {
    const regex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    return regex.test(value);
  }
}

export {
  validateDate,
  notify,
  validateSpecialInputFields,
  maskInput,
  upsertFieldsNotFoundOrEmpty,
  requiredZodErr,
  extractAPIErrMsg,
  toUSD,
  getInsuranceData,
  getIsDevEnv,
  getValidCode,
  filterValue,
  getIsExpired,
  getPolicyExpired,
  getRenewalPolicyStatus,
  getItemRenewalPolicyStatus,
  getPrimaryContact,
  batchRun,
  validateEmail,
  formatNumber,
  validateNumber
};
