export function formatDollarAmount(amount) {
  if (amount === undefined || amount === null) {
    return "";
  }

  if (typeof amount === "number") {
    return `$${amount.toLocaleString()}`;
  } else if (
    typeof amount === "string" &&
    amount.trim() !== "" &&
    !isNaN(amount.replace(/,/g, "")) // Remove commas before checking if it's a number
  ) {
    return `$${parseInt(amount.replace(/,/g, ""), 10).toLocaleString()}`; // Remove commas before parsing
  } else {
    return "";
  }
}
