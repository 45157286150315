import React, { useState } from "react";
import { Descriptions } from "antd";
import { Input } from "../../../../../components";
import { ButtonGroup } from "@components";
import { Card, Loading, ScreenMask } from "@components";
import { Button, Switch, Select, Radio, Form } from "antd";
import { DeleteOutlined, UserOutlined, EditOutlined } from "@ant-design/icons";
import { formatNumber } from "@utils/common";
import { BUTTON_GROUP_TYPE_SWITCH } from "@components/button-group";
import { ROLE_LIST } from "@assets/const/ui";
import POCEdit from "@components/modals/poc-edit";
import ZorPocEdit from "@components/modals/zor-poc-edit";
import ZorPOCDelete from "@components/modals/zor-poc-delete";
import UserContactCard from "@components/user-contact-card";
import { FORM_TYPE } from "@assets/const/ui";
import "./style.scss";

const SCHEDULE_OPTIONS = [
  {
    label: "1d",
    value: "1d"
  },
  {
    label: "3d",
    value: "3d"
  },
  {
    label: "5d",
    value: "5d"
  },
  {
    label: "15d",
    value: "15d"
  },
  {
    label: "30d",
    value: "30d"
  }
];

export default function ContactItem(props) {
  const { data, onChange, onClose, handleSave } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  function handleDelete() {
    setIsDeleteOpen(true);
  }

  function handleOpen() {
    setIsOpen(true);
  }

  function handleClose() {
    onClose();
  }

  console.log("zor poc close", isOpen);

  return (
      <div>
        <UserContactCard
          userData={data}
          handleEdit={handleOpen}
          handleDeleteOpen={handleDelete}
          formType={FORM_TYPE}
        />
        <ZorPocEdit
          handleSave={handleSave}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          userData={data}
          onChange={onChange}
        />
        <ZorPOCDelete
          isOpen={isDeleteOpen}
          onClose={() => setIsDeleteOpen(false)}
          onDelete={onClose}
          handleSave={handleSave}
        />
      </div>
  );
}
