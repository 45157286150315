import React, { useState } from 'react'
import { Button, Form, Input as AntInput, Radio, Select, Switch, DatePicker, Descriptions } from "antd";
import { isEmpty } from "lodash";
import dayjs from "dayjs";

import Input from "@components/input";
import { usePlacesWidget } from "react-google-autocomplete";
import { extractLocationData } from "@utils/google-map";
import {
  TYPE_INPUT_PRIMARY,
  TYPE_INPUT_PHONE,
  TYPE_INPUT_YEAR,
  TYPE_INPUT_ADDRESS,
  TYPE_INPUT_DATE,
  TYPE_INPUT_DIGIT,
  TYPE_INPUT_TAX_ID,
  TYPE_INPUT_VIN,
  TYPE_INPUT_CURRENCY
} from "@assets/const/ui";
import { ENTITY_CODE } from "@assets/const/legal-entity";
import { APP_DATE_FORMAT } from "@assets/const/form";
import { getAddressItem } from "@utils/address";
import './style.scss';

export default function ZeeInsInfoEditForm({ onChange, data, handleSave, onClose }) {

  const [entityName, setEntityName] = useState(data?.entity_name);
  const [dba, setDba] = useState(data?.dba);
  const [address, setAddress] = useState(data?.address);
  const [city, setCity] = useState(data?.city);
  const [state, setState] = useState(data?.state);
  const [zip, setZip] = useState(data?.zip);
  const [taxId, setTaxId] = useState(data?.tax_id || data?.detail?.fimEntity?.fimTtTaxpayer);
  const [openingDate, setOpeningDate] = useState(data?.opening_date || data?.detail?.openingDate);
  const [centerName, setCenterName] = useState(data?.centerName);
  const [annualRevenue, setAnnualRevenue] = useState(data?.annualRevenue);
  const [entityType, setEntityType] = useState(data?.entity_type);

  // TODO: fix issue with Google Places autocomplete not being stored in setAddress
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    options: {
      types: ["address"]
    },
    onPlaceSelected: (place) => {
      const { zip, city, state, address } = extractLocationData(
        place.address_components
      );

      setAddress(address);

      onChange((prev) => ({
        ...prev,
        city: city,
        state: state,
        zip: zip,
        address: address
      }));
    }
  });

  function handleSubmit() {

    const formData = {
      "entity_name": entityName,
      "dba": dba,
      "address": address,
      "entity_type": entityType,
      "opening_date": openingDate,
      "tax_id": taxId,
      "centerName": centerName,
      "annualRevenue": annualRevenue,
      "city": city,
      "state": state,
      "zip": zip
    };

    Object.entries(formData).forEach(([key, value]) => {
      onChange((prev) => ({ ...prev, [key]: value }));
    });

    handleSave(formData);
    onClose();
  }

  return (
    <div className="zee-ins-info-edit-form">
      <Form>
        <div className='form-group-container'>
          <div>
            <label className="input-title">Entity Name</label>
            <Form.Item>
              <AntInput
                className='ant-input'
                value={entityName}
                onChange={(e) => setEntityName(e.target.value)}
              />
            </Form.Item>
          </div>
          <div>
            <label className="input-title">Entity Type</label>
            <Form.Item>
              <Select
                size="large"
                style={{ minWidth: "120px", width: "100%" }}
                value={entityType}
                onChange={(value) => setEntityType(value)}
              >
                {ENTITY_CODE.map((item) => (
                  <Select.Option key={item?.value} value={item?.value}>
                    {item?.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className='form-group-container'>
          <div>
            <label className="input-title">DBA</label>
            <Form.Item>
              <AntInput
                value={dba}
                onChange={(e) => setDba(e.target.value)}
              />
            </Form.Item>
          </div>
          <div>
          <label className="input-title">Tax ID</label>
          <Form.Item>
            <Input
              inputType={TYPE_INPUT_TAX_ID}
              value={taxId}
              onChange={(evt) => setTaxId(evt.target.value)}
            />
          </Form.Item>
          </div>
        </div>
        <div className='form-group-container'>
          <div>
            <label className="input-title">Estimated Annual Revenue</label>
            <Form.Item>
              <Input
                style={{ width: "100%", height: "40px", fontSize: "14px" }}
                inputType={TYPE_INPUT_CURRENCY}
                value={annualRevenue}
                onChange={(value) => setAnnualRevenue(value)}
              />
            </Form.Item>
          </div>
          <div>
            <label className="input-title">Opening Date</label>
            <Form.Item>
              <DatePicker
                style={{ width: "100%", height: "40px", fontSize: "14px" }}
                format={APP_DATE_FORMAT}
                value={!isEmpty(openingDate) ? dayjs(openingDate) : null}
                onChange={(_, dateString) => setOpeningDate(dateString)}
              />
            </Form.Item>
          </div>
        </div>
        <div className='form-single-container'>
          <div>
            <label className="input-title">Street Address</label>
            <Form.Item>
              <GoogleMapsWrapper
                streetAddress={address}
                setStreetAddress={setAddress}
                addressForm={{}}
                setAddressForm={() => {}}
                getAddressItem={getAddressItem}
                setState={setState}
                setCity={setCity}
                setZipCode={setZip}
              />
            {/* <input
              // ref={ref} // Attach ref for Google Places autocomplete
              value={address}
              placeholder="Enter Address"
              onChange={(e) => setAddress(e.target.value)} // Update state when address changes
              style={{
                width: "100%",
                paddingLeft: "10px",
                border: "1px solid #d9d9d9",
                borderRadius: "4px",
                height: "40px",
              }}
  /> */}
            </Form.Item>
          </div>
        </div>
        <div className='form-trio-container'>
          <div>
            <label className="input-title">City</label>
            <Form.Item>
              <AntInput
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </Form.Item>
          </div>
            <div>
              <label className="input-title">State</label>
              <Form.Item>
                <AntInput
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </Form.Item>
          </div>
          <div>
            <label className="input-title">Zip Code</label>
            <Form.Item>
              <AntInput
                value={zip}
                onChange={(e) => setZip(e.target.value)}
              />
            </Form.Item>
          </div>
        </div>
        <div className='form-group-container'>
          <div>
            <label className="input-title">Center Name / Location Number</label>
            <Form.Item>
              <AntInput
                value={centerName}
                onChange={(e) => setCenterName(e.target.value)}
              />
            </Form.Item>
          </div>
          <div></div>
        </div>
        <div className='poc-cr-btns'>
          <Button type="default" onClick={onClose}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </Form>
  </div>
  );

}


function GoogleMapsWrapper({ streetAddress, setStreetAddress, addressForm, setAddressForm, getAddressItem, setState, setCity, setZipCode }) {
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    options: {
      types: ["address"],
    },
    onPlaceSelected: (place) => {
      if (place.address_components) {
        const { zip, city, state, street, route } = extractLocationData(
          place.address_components
        );

        setStreetAddress(`${street} ${route}`)
        setState(state)
        setCity(city)
        setZipCode(zip)
      }
    },
  });

  return (
    <input
      className='ant-input ant-input-lg css-dev-only-do-not-override-1okl62o ant-input-outlined'
      ref={ref}
      type='text'
      inputType="TYPE_INPUT_ADDRESS"
      required={true}
      placeholder="Street Address"
      size="large"
      value={streetAddress}
      onChange={(evt) => setStreetAddress(evt.target.value)}
    />
  )
}
