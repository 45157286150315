import React from 'react'
import {
  DeleteOutlined,
  UserAddOutlined,
  UserOutlined,
  EditOutlined
} from "@ant-design/icons";
import { Descriptions } from "antd";
import { FORM_TYPE } from '@assets/const/ui';

import { formatNumber } from "@utils/common";

import "./style.scss";

export default function UserContactCard({
  formType,
  userData,
  handleOpen,
  handleDelete,
  handleEdit,
  handleDeleteOpen,
  franchiseeData,
  index
}) {
  return (
    <div className="poc_contacts_item">
      <div className="poc_contacts_item-wrapper">
        <div className="poc_contacts_item-primary-details">
          <div className="poc_contacts_item-primary-details-upper">
            <UserOutlined className="poc_contacts_item-avatar" />
            <p className="poc_contacts_item-fullname">
              {userData?.firstName} {userData?.lastName}
            </p>
            <p className='poc_contacts_item-role'>{userData?.role?.toUpperCase()}</p>
          </div>
          <div className="poc_contacts_item-action-btns">
              <div>
                <div>
                  <EditOutlined
                    onClick={() => handleEdit(userData, index)}
                  />
                </div>
                <div className="poc_contacts_item-action">
                  <DeleteOutlined
                    onClick={() => handleDeleteOpen(userData, index)}
                  />
                </div>
              </div>
          </div>
        </div>
        <div className="poc_contacts_item-secondary-details">
          <div>
            <p>{franchiseeData?.insuranceData?.entity_name}</p>
            <div>
              <p>C: {formatNumber(userData?.telephone)}</p>
              { formType !== FORM_TYPE && <p>D: {formatNumber(userData?.directLine)}</p> }
              <p>{userData?.email}</p>
            </div>
            {/* <div>
              <p>Preferred Contact Method: {contactItem?.preferredMethod ? contactItem?.preferredMethod?.charAt(0).toUpperCase() + contactItem?.preferredMethod?.slice(1) : ""}</p>
            </div> */}
          </div>
          <div className="poc_contacts_item-switches">
            <p>Primary: {userData?.isPrimary ? "On" : "Off"}</p>
            <p>Active: {userData?.isActive ? "On" : "Off"}</p>
            <div>
              { formType !== FORM_TYPE && (
                <p>
                  Preferred Contact Method:{" "}
                  {userData?.preferredMethod
                    ? userData?.preferredMethod?.charAt(0).toUpperCase() +
                      userData?.preferredMethod?.slice(1)
                    : ""}
                </p>
              )}
            </div>
          </div>
        </div>
        { formType === FORM_TYPE && (
          <div className="form_profile">
          <Descriptions bordered title="Reports" column={1} style={{ whiteSpace: "nowrap" }}>
            <Descriptions.Item label="Executive Summary Report - Insurance Report">
              {userData?.isReport ? "On" : "Off"}{userData?.reportSchedule ? ` / ${userData.reportSchedule}` : null}
            </Descriptions.Item>

          </Descriptions>
        </div>
        )}
      </div>
    </div>
  );
}
