import { useState } from 'react'
import { Modal, Form, Input as AntInput, Button, Switch, Select } from 'antd'



export default function ZorPOCDelete({ isOpen, onClose, onDelete, handleSave }) {

  function handleDelete() {
    onDelete()
    handleSave()
    onClose()
  }

  return (
    <Modal
      title={<span className='modal-title'>Delete User</span>}
      open={isOpen}
      onCancel={onClose}
      footer={
        <div className="delete-modal__buttons">
          <Button type="default" size="large" onClick={onClose}>Cancel</Button>
          <Button type="primary" htmlType="submit" size="large" onClick={handleDelete} danger>Delete</Button>
        </div>
      }
    >
      <p>Are you sure you want to delete this user?</p>

    </Modal>
  )
}
