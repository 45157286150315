import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useAppState } from "@context";
import { Input, Loading } from "@components";
import { Button, notification, Select, Switch } from "antd";
import { EditOutlined } from "@ant-design/icons";
import Clock from "react-clock";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { usePlacesWidget } from "react-google-autocomplete";
import { getAddressItem } from "@utils/address";
import { post, put } from "@utils/axios";
import "react-clock/dist/Clock.css";
import {
  REQUIREMENT_STATUS_DASHBOARD,
  REQUIREMENT_STATUS_ONBOARD
} from "@assets/const/status";
import { OTHER_HOST_LIST } from "@utils/host";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import Card from "@components/card";
import RealTimeNotifications from "../real-time-notifications";
import { getIsDevEnv } from "@utils/common";
import InformationTable from "@components/information-table";
import ZorAccInfoEdit from "@components/modals/zor-acc-info-edit";
import "./style.scss";

export default function PersonalInfor(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { franchisorAdmin, setFranchisorAdmin } = useAppState();
  const [loading, setLoading] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [showZorEdit, setShowZorEdit] = useState(false);

  const [formVal, setFormVal] = useState({
    dba: franchisorAdmin?.dba || "",
    host: franchisorAdmin?.host || "",
    personal_infor_business_name:
      franchisorAdmin?.personal_infor_business_name || "",
    personal_infor_logo: franchisorAdmin?.personal_infor_logo || "",
    rmsInsVer: franchisorAdmin?.rmsInsVer,
    enableRMSCompliance: franchisorAdmin?.enableRMSCompliance
  });

  const [addressForm, setAddressForm] = useState({
    personal_infor_address: franchisorAdmin?.personal_infor_address || "",
    personal_infor_city: franchisorAdmin?.personal_infor_city || "",
    personal_infor_state: franchisorAdmin?.personal_infor_state || "",
    personal_infor_zip: franchisorAdmin?.personal_infor_zip || ""
  });

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    options: {
      types: ["address"]
    },
    onPlaceSelected: (place) => {
      let zipCode = "",
        state = "",
        city = "";
      if (place.address_components) {
        zipCode = getAddressItem(
          place.address_components,
          "postal_code",
          "short_name"
        );
        state = getAddressItem(
          place.address_components,
          "administrative_area_level_1",
          "short_name"
        );
        city = getAddressItem(
          place.address_components,
          "locality",
          "long_name"
        );
        const street = getAddressItem(
          place.address_components,
          "street_number",
          "long_name"
        );
        const route = getAddressItem(
          place.address_components,
          "route",
          "long_name"
        );
        setAddressForm({
          ...addressForm,
          personal_infor_city: city,
          personal_infor_state: state,
          personal_infor_zip: zipCode,
          personal_infor_address: `${street} ${route}`
        });
      }
    }
  });

  useEffect(() => {
    if (franchisorAdmin) {
      setFormVal({
        dba: franchisorAdmin.dba || "",
        host: franchisorAdmin.host || "",
        personal_infor_business_name:
          franchisorAdmin.personal_infor_business_name || "",
        personal_infor_logo: franchisorAdmin.personal_infor_logo || "",
        rmsInsVer: franchisorAdmin.rmsInsVer,
        enableRMSCompliance: franchisorAdmin?.enableRMSCompliance
      });
      setAddressForm({
        personal_infor_address: franchisorAdmin.personal_infor_address || "",
        personal_infor_city: franchisorAdmin.personal_infor_city || "",
        personal_infor_state: franchisorAdmin.personal_infor_state || "",
        personal_infor_zip: franchisorAdmin.personal_infor_zip || ""
      });
    }
  }, [franchisorAdmin]);

  // useEffect(() => {
  //   const interval = setInterval(() => setCurrentTime(new Date()), 1000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);


  function handleZorEdit() {
    setShowZorEdit(true);
  }

  function handleZorEditClose() {
    setShowZorEdit(false);
  }

  function handleChange(key, value) {
    const newFormVals = formVal;
    newFormVals[key] = value;
    setFormVal({
      ...newFormVals
    });

  }

  function handleChangeAddress(key, value) {
    const newAddressForm = addressForm;
    newAddressForm[key] = value;
    setAddressForm({
      ...newAddressForm
    });
  }

  async function handleFormSubmit() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      let param = {
        ...formVal,
        ...addressForm
      };

      if (
        formVal.personal_infor_business_name !==
        franchisorAdmin?.personal_infor_business_name
      ) {
        param = {
          ...param,
          additional_infor_business_name: formVal.personal_infor_business_name
        };
      }

      if (formVal.dba !== franchisorAdmin?.dba) {
        param = {
          ...param,
          additional_infor_dba: formVal.dba
        };
      }

      if (
        addressForm.personal_infor_address !==
          franchisorAdmin?.personal_infor_address ||
        addressForm.personal_infor_city !==
          franchisorAdmin?.personal_infor_city ||
        addressForm.personal_infor_state !==
          franchisorAdmin?.personal_infor_state ||
        addressForm.personal_infor_zip !== franchisorAdmin?.personal_infor_zip
      ) {
        param = {
          ...param,
          additional_infor_address: addressForm.personal_infor_address,
          additional_infor_city: addressForm.personal_infor_city,
          additional_infor_state: addressForm.personal_infor_state,
          additional_infor_zip: addressForm.personal_infor_zip
        };
      }

      if (franchisorAdmin?._id) {
        const result = await put(
          `requirement/${franchisorAdmin._id}`,
          { ...param },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (result.data.success) {
          const requirement = result.data.data;
          setFranchisorAdmin({ ...franchisorAdmin, ...requirement });
          setLoading(false);
        }
      } else {
        const result = await post(
          `requirement`,
          { ...param },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (result.data.success) {
          const requirement = result.data.data;
          setFranchisorAdmin({ ...franchisorAdmin, ...requirement });
          setLoading(false);
        }
      }
    } catch (e) {
      handleNotification(
        "Failed",
        "Save form is failed. Please contact to admin directly."
      );
      setLoading(false);
    }
  }

  async function updateData(param) {
    try {
      const token = await getAccessTokenSilently();
      const result = await put(
        `requirement/${franchisorAdmin._id}`,
        { ...param },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (result.data.success) {
        return result.data.data;
      }

      return null;
    } catch (error) {
      return null;
    }
  }

  async function createData(param) {
    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `requirement`,
        { ...param },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (result.data.success) {
        return result.data.data;
      }

      return null;
    } catch (error) {
      handleNotification(
        "Failed",
        "Save form is failed. Please contact to admin directly."
      );
      return null;
    }
  }

  async function handleEmailZorReport(value) {
    setLoading(true);
    const param = {
      emailZorReport: value
    };

    const result = await updateData(param);
    if (result) {
      setFranchisorAdmin({ ...franchisorAdmin, ...result });
    }
    setLoading(false);
  }

  async function handleOutReachStatus(value) {
    // setLoading(true);
    const param = {
      outReachStatus: value
    };

    const result = await updateData(param);
    if (result) {
      setFranchisorAdmin({ ...franchisorAdmin, ...result });
    }
    // setLoading(false);
    await handleSyncAC();
  }

  async function handleCampaignMode(value) {
    // setLoading(true);
    const param = {
      campaign_mode: value
    };

    const result = await updateData(param);
    if (result) {
      setFranchisorAdmin({ ...franchisorAdmin, ...result });
    }
    // setLoading(false);
    await handleSyncAC();
  }

  async function handleActivate(value) {
    setLoading(true);
    const param = {
      status: value ? REQUIREMENT_STATUS_DASHBOARD : REQUIREMENT_STATUS_ONBOARD
    };
    if (franchisorAdmin?._id) {
      const result = await updateData(param);
      if (result) {
        setFranchisorAdmin({ ...franchisorAdmin, ...result });
      }
    } else {
      const result = await createData(param);
      if (result) {
        setFranchisorAdmin({ ...franchisorAdmin, ...result });
      }
    }

    setLoading(false);
    await handleSyncAC();
  }

  function handleSave() {
    handleFormSubmit();
  }

  const domainPermission = useMemo(() => {
    switch (window.location.hostname) {
      case "app.rikor.io":
      case "dev.app.rikor.io":
      case "localhost":
        return true;
      default:
        return false;
    }
  }, [window?.location]);

  async function handleSyncAC() {
    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `admin/active-campaign/sync-franchisor/${franchisorAdmin._id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {}
  }

  async function handleNotifyEmail() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `reports/email/${franchisorAdmin._id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {}
    setLoading(false);
  }

  async function handleDomainFranchizee(hostVal) {
    // setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const param = {
        host: hostVal
      };
      if (franchisorAdmin?._id) {
        const result = await post(
          `requirement/${franchisorAdmin._id}/host`,
          { ...param },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        const newFormVals = formVal;
        newFormVals.host = hostVal;
        setFormVal({
          ...newFormVals
        });
      }
    } catch (error) {}
    // setLoading(false);
  }

  function handleNotification(title, description) {
    notification.open({
      message: title,
      description: description,
      duration: 3
    });
  }

  const generateStatus = useCallback((data) => {
    switch (data) {
      case REQUIREMENT_STATUS_ONBOARD:
        return "Pending";
      case REQUIREMENT_STATUS_DASHBOARD:
        return "Activated";
    }
  }, []);

  let zone = new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];

  const isDevEnv = useMemo(() => {
    return getIsDevEnv(window.origin);
  }, [window?.origin]);

  return (
    <div className="in_per">
      <div className="in_per-account">
        <Card title={<div className="in_per-account-title">
          <span>Account Information</span>
          <EditOutlined
            className="in_per-account-title-edit"
            onClick={() => {
              setShowZorEdit(true)}}
          />
        </div>}>
          <InformationTable formVals={formVal} addressForm={addressForm} franchisorAdmin={franchisorAdmin} />
          <Loading loading={loading} />
        </Card>
      </div>
      <div className="in_per-contact">
        <RealTimeNotifications
          visibleReportBtn={isDevEnv}
          onNotifyEmail={handleNotifyEmail}
        />
      </div>
      <ZorAccInfoEdit
        domainPermission={domainPermission}
        ref={ref}
        isOpen={showZorEdit}
        onClose={handleZorEditClose}
        formVals={formVal}
        addressForm={addressForm}
        handleSave={handleSave}
        franchisorAdmin={franchisorAdmin}
        handleChange={handleChange}
        handleCampaignMode={handleCampaignMode}
        handleOutReachStatus={handleOutReachStatus}
        handleActivate={handleActivate}
        handleDomainFranchizee={handleDomainFranchizee}
        handleChangeAddress={handleChangeAddress}
        setLoading={setLoading}
        setAddressForm={setAddressForm}

      />
    </div>
  );
}
