import React from "react";
import { Descriptions } from "antd";
import { formatDollarAmount } from "@utils/dollar-formatter";

const InformationTable = ({ formVals, addressForm, franchisorAdmin }) => {
  const combinedObj = { ...formVals, ...addressForm, ...franchisorAdmin };

  const valMap = {
    entity_name: "Entity Name",
    entity_type: "Entity Type",
    dba: "DBA (Doing Business As)",
    address: "Street Address",
    city: "City",
    state: "State",
    zip: "Zip",
    tax_id: "Tax ID",
    opening_date: "Opening Date",
    centerName: "Center Name/Location Number",
    annualRevenue: "Estimated Annual Revenue",
    personal_infor_business_name: "Entity Name",
    personal_infor_address: "Address",
    personal_infor_city: "City",
    personal_infor_state: "State",
    personal_infor_zip: "Zip",
    host: "Host",
    rmsInsVer: "Policy Status Verification",
    enableRMSCompliance: "Compliance Status Verification",
    outReachStatus: "Outreach Status",
    campaign_mode: "Campaign Mode",
    personal_infor_logo: "Logo URL",
  };

  const ZorDisplayOrder = [
    "personal_infor_business_name" ,
    "dba",
    "personal_infor_address",
    "personal_infor_city",
    "personal_infor_state",
    "personal_infor_zip",
    "rmsInsVer",
    "enableRMSCompliance",
    "outReachStatus",
    "campaign_mode",
    "personal_infor_logo",
    "host"
  ];

  const ZeeDisplayOrder = [
    "entity_name",
    "dba",
    "entity_type",
    "address",
    "city",
    "state",
    "zip",
    "tax_id",
    "opening_date",
    "centerName",
    "annualRevenue",
  ];

  const chosenOrderList = formVals.hasOwnProperty("personal_infor_business_name") ? ZorDisplayOrder : ZeeDisplayOrder;

  function formatTaxId(taxId) {
    if (taxId && taxId.length === 9) {
      const taxIdArr = taxId.split("");
      taxIdArr.splice(2, 0, "-");
      return taxIdArr.join("");
    }
    return taxId;
  };


  return (
    <div className="form_profile">
      <div>
        <Descriptions bordered column={1} labelStyle={{ width: "20em" }}>
          {chosenOrderList
            .filter((key) => (valMap.hasOwnProperty(key)) || combinedObj[key] === undefined) // filter out empty values
            .map((key) => {
              if (key === "annualRevenue") {
                return (
                  <Descriptions.Item label={valMap[key]} key={key}>
                    {formatDollarAmount(combinedObj[key])}
                  </Descriptions.Item>
                );
              }
              if (key === "tax_id") {
                return (
                  <Descriptions.Item label={valMap[key]} key={key}>
                    {formatTaxId(combinedObj[key])}
                  </Descriptions.Item>
                );
              }
              if (
                key === "rmsInsVer" ||
                key === "enableRMSCompliance" ||
                key === "outReachStatus" ||
                key === "campaign_mode"
              ) {
                return (
                  <Descriptions.Item label={valMap[key]} key={key}>
                    {combinedObj[key] ? "On" : combinedObj[key] === undefined ? "Off" : "Off"}
                  </Descriptions.Item>
                );
              }
              return (
                <Descriptions.Item label={valMap[key]} key={key}>
                  {combinedObj[key]}
                </Descriptions.Item>
              );
            })}
        </Descriptions>
      </div>
    </div>
  );
};

export default InformationTable;
