import React from "react";
import { Input } from "@components";
import { DatePicker, Select, Descriptions } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { ENTITY_CODE } from "@assets/const/legal-entity";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { usePlacesWidget } from "react-google-autocomplete";
import { extractLocationData } from "@utils/google-map";
import { formatDollarAmount } from "@utils/dollar-formatter";
import { getAddressItem } from "@utils/address";
import { TYPE_INPUT_TAX_ID } from "@assets/const/ui";
import { APP_DATE_FORMAT } from "@assets/const/form";
import InformationTable from "@components/information-table";
import "./style.scss";

const { Option } = Select;

export default function FormProfile(props) {
  const { data: formVal, onChange } = props;

  function handleChange(key, value) {
    onChange((prev) => ({ ...prev, [key]: value }));
  }

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    options: {
      types: ["address"]
    },
    onPlaceSelected: (place) => {
      const { zip, city, state, address } = extractLocationData(
        place.address_components
      );

      onChange((prev) => ({
        ...prev,
        city: city,
        state: state,
        zip: zip,
        address: address
      }));
    }
  });

  const formatTaxId = (taxId) => {
    if (taxId && taxId.length === 9) {
      const taxIdArr = taxId.split("");
      taxIdArr.splice(2, 0, "-");
      return taxIdArr.join("");
    }
    return taxId;
  }

  const valMap = {
    entity_name: "Entity Name",
    dba: "DBA (Doing Business As)",
    address: "Street Address",
    city: "City",
    state: "State",
    zip: "Zip",
    tax_id: "Tax ID",
    opening_date: "Opening Date",
    centerName: "Center Name/Location Number",
    annualRevenue: "Estimated Annual Revenue"
  };

  return (
    <div className="form_profile">
     <div>
        <Descriptions bordered column={1} labelStyle={{ width: "20em" }}>
          <Descriptions.Item label="Entity Name">
            {formVal?.entity_name || ""}
          </Descriptions.Item>

          <Descriptions.Item label="DBA (Doing Business As)">
            {formVal?.dba || ""}
          </Descriptions.Item>

          <Descriptions.Item label="Street Address">
            {formVal?.address || ""}
          </Descriptions.Item>

          <Descriptions.Item label="City">
            {formVal?.city || ""}
          </Descriptions.Item>

          <Descriptions.Item label="State">
            {formVal?.state || ""}
          </Descriptions.Item>

          <Descriptions.Item label="Zip">
            {formVal?.zip || ""}
          </Descriptions.Item>

          <Descriptions.Item label="Tax ID">
            {formVal?.tax_id || formVal?.detail?.fimEntity?.fimTtTaxpayer
              ? formatTaxId(
                  formVal?.tax_id || formVal?.detail?.fimEntity?.fimTtTaxpayer
                )
              : ""}
          </Descriptions.Item>

          <Descriptions.Item label="Opening Date">
            {formVal?.opening_date || formVal?.detail?.openingDate || ""}
          </Descriptions.Item>

          <Descriptions.Item label="Center Name/Location Number">
            {formVal?.centerName || ""}
          </Descriptions.Item>

          <Descriptions.Item label="Estimated Annual Revenue">
            {formVal?.annualRevenue
              ? formatDollarAmount(formVal?.annualRevenue)
              : ""}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
}
