import React, { useEffect, useState } from "react";
import { Card, Input, Loading, ScreenMask } from "@components";
import { Button, Switch, Select, Radio, Form } from "antd";
import {
  DeleteOutlined,
  UserAddOutlined,
  UserOutlined,
  EditOutlined
} from "@ant-design/icons";

import UserContactCard from "@components/user-contact-card";
import { useAuth0 } from "@auth0/auth0-react";
import { put } from "@utils/axios";
import { getPrimaryContact } from "@utils/common";
import POCCreate from "@components/modals/poc-create";
import POCEdit from "@components/modals/poc-edit";
import POCDelete from "@components/modals/poc-delete";
import { formatNumber } from "@utils/common";
import { PREFERRED_CONTACT_METHOD_LIST, ROLE_LIST } from "@assets/const/ui";
import "./style.scss";

export default function POCContact(props) {
  const { franchiseeData, setFranchiseeData, openOnMount = false } = props;
  const { getAccessTokenSilently, user } = useAuth0();
  const [contactList, setContactList] = useState([]);
  const [logPOCContacts, setLogPOCContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedContactIndex, setSelectedContactIndex] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [zeeForm] = Form.useForm();

  useEffect(() => {
    setContactList(handleGetFormVal());
    setLogPOCContacts(franchiseeData?.log_poc_contacts || []);

    if (openOnMount) {
      setIsOpen(true);
    }
  }, [franchiseeData]);

  function handleChange(index, key, value) {
    contactList[index][key] = value;
    setContactList([...contactList]);
  }

  function handleGetFormVal() {
    const pocContacts = JSON.parse(
      JSON.stringify(franchiseeData?.poc_contacts || [])
    );
    const primaryContact = getPrimaryContact(franchiseeData);

    if (
      primaryContact?.email &&
      !pocContacts.find(
        (contactItem) => contactItem.email === primaryContact.email
      )
    ) {
      //contacts toggle values are assigned to the master toggles
      pocContacts.unshift(primaryContact);
    }
    return pocContacts.map((contact) => ({
      ...contact
    }));
  }

  async function handleSave() {
    setLoading(true);
    logPOCContacts.push({
      updatedBy: user.email,
      updatedAt: new Date(),
      data: contactList
    });
    try {
      const token = await getAccessTokenSilently();
      const result = await put(
        `contact/${franchiseeData._id}`,
        {
          poc_contacts: contactList,
          log_poc_contacts: logPOCContacts
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setLoading(false);
      setLogPOCContacts([...logPOCContacts]);
    } catch (error) {
      setLoading(false);
    }
  }

  function handleAdd() {
    setIsOpen(true);
  }

  function handleEditClose() {
    setIsEditOpen(false);
    setSelectedContact(null);
    zeeForm.setFieldsValue({
      firstName: "",
      lastName: "",
      email: "",
      telephone: "",
      directLine: "",
      preferredMethod: "",
      role: ""
    });
  }

  function handleEdit(contactItem, index) {
    setIsEditOpen(true);
    setSelectedContact(contactItem);
    setSelectedContactIndex(index);
    zeeForm.setFieldsValue({
      firstName: contactItem.firstName,
      lastName: contactItem.lastName,
      email: contactItem.email,
      telephone: contactItem.telephone,
      directLine: contactItem.directLine,
      preferredMethod: contactItem.preferredMethod,
      role: contactItem.role
    });
  }

  function handleDeleteOpen(contactItem, index) {
    setSelectedContactIndex(index);
    setSelectedContact(contactItem);
    setIsDeleteOpen(true);
  }

  function handleDeleteClose() {
    setIsDeleteOpen(false);
    setSelectedContactIndex(null);
  }

  function handleDelete() {
    contactList.splice(selectedContactIndex, 1);
    setContactList([...contactList]);
    setIsDeleteOpen(false);
    setSelectedContactIndex(null);
    handleSave();
  }

  const renderContactItem = (contactItem, index, disabled) => {
    return (
      <div className="poc_contacts_item">
        <div className="poc_contacts_item-wrapper">
          <div className="poc_contacts_item-primary-details">
            <div className="poc_contacts_item-primary-details-upper">
              <UserOutlined className="poc_contacts_item-avatar" />
              <p className="poc_contacts_item-fullname">
                {contactItem?.firstName} {contactItem?.lastName}
              </p>
            </div>
            <div className="poc_contacts_item-action-btns">
              <p>{contactItem?.role?.toUpperCase()}</p>

                <div>
                  <div>
                    <EditOutlined
                      onClick={() => handleEdit(contactItem, index)}
                    />
                  </div>
                  <div className="poc_contacts_item-action">
                    <DeleteOutlined
                      onClick={() => handleDeleteOpen(contactItem, index)}
                    />
                  </div>
                </div>

            </div>
          </div>
          <div className="poc_contacts_item-secondary-details">
            <div>
              <p>{franchiseeData?.insuranceData?.entity_name}</p>
              <div>
                <p>C: {formatNumber(contactItem?.telephone)}</p>
                <p>D: {formatNumber(contactItem?.directLine)}</p>
                <p>{contactItem?.email}</p>
              </div>
              {/* <div>
                <p>Preferred Contact Method: {contactItem?.preferredMethod ? contactItem?.preferredMethod?.charAt(0).toUpperCase() + contactItem?.preferredMethod?.slice(1) : ""}</p>
              </div> */}
            </div>
            <div className="poc_contacts_item-switches">
              <p>Primary: {contactItem?.isPrimary ? "On" : "Off"}</p>
              <p>Active: {contactItem?.isActive ? "On" : "Off"}</p>
              <div>
                <p>
                  Preferred Contact Method:{" "}
                  {contactItem?.preferredMethod
                    ? contactItem?.preferredMethod?.charAt(0).toUpperCase() +
                      contactItem?.preferredMethod?.slice(1)
                    : ""}
                </p>
              </div>
            </div>
          </div>
        </div>
        {disabled && <ScreenMask />}
      </div>
    );
  };

  return (
    <Card
      title="Users"
      extra={() => (
        <Button onClick={handleAdd} type="primary add-user-btn">
          <UserAddOutlined /> Add User
        </Button>
      )}
    >
      <div className="poc_contacts">
        {contactList.map((contactItem, index) => {
          // return renderContactItem(contactItem, index);
          return (
            <UserContactCard
              key={index}
              userData={contactItem}
              handleEdit={handleEdit}
              handleDeleteOpen={handleDeleteOpen}
              franchiseeData={franchiseeData}
              index={index}
            />
          );
        })}
        {/* <div className="poc_contacts-footer">
          <Button size="large" type="primary" onClick={handleSave}>
            Save
          </Button>
        </div> */}
        <Loading loading={loading} />
      </div>
      <POCCreate
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        franchiseeData={franchiseeData}
        setFranchiseeData={setFranchiseeData}
      />
      <POCEdit
        isOpen={isEditOpen}
        onClose={handleEditClose}
        selectedContact={selectedContact}
        franchiseeData={franchiseeData}
        setFranchiseeData={setFranchiseeData}
        zeeForm={zeeForm}
        handleChange={handleChange}
        selectedContactIndex={selectedContactIndex}
        handleSave={handleSave}
      />
      <POCDelete
        isOpen={isDeleteOpen}
        onClose={handleDeleteClose}
        handleDelete={handleDelete}
      />
    </Card>
  );
}
