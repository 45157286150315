import React, { useState, useEffect } from "react";
import { Input } from "../../../../components";
import { Alert } from "antd";
import "./style.scss";
import {
  FORM_EXCLUDED,
  FORM_INCLUDED,
  FORM_LOSS_DISCOVERED,
  FORM_LOSS_SUSTAINED,
} from "../../../../assets/const/form";
import {
  buildEffectDate,
  buildExpireDate,
  buildValueGT,
  buildEpliThirdParty,
  buildValueLT,
} from "../../../../utils/build-logic";
import { STATUS_SECTION_APPROVE, DOC_MAX_LIMIT } from "@assets/const/status";
import { mapFieldEPLI } from "@utils/form-recog";
import { CheckCircleTwoTone, WarningTwoTone } from "@ant-design/icons";
import { capitalize } from "lodash";
import { formatToMonthDayYear } from "@utils/date-formatter";
import { formatDollarAmount } from "@utils/dollar-formatter";

export default function EpliForm(props) {
  const { docIndex, franchisorData, franchiseeData, toggleState } = props;

  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (
      franchiseeData?.finalData?.epli_form_status === STATUS_SECTION_APPROVE
    ) {
      const finalData = franchiseeData.finalData;

      return {
        epli_occ: finalData.epli_occ,
        epli_agg: finalData.epli_agg,
        epli_third_party: finalData.epli_third_party,
        epli_wage: finalData.epli_wage,
        epli_mx: finalData.epli_mx,
        epli_policy_number: finalData.epli_policy_number,
        epli_effective_date: finalData.epli_effective_date,
        epli_expire_date: finalData.epli_expire_date,
      };
    }

    if (docIndex >= 0 && docIndex !== DOC_MAX_LIMIT) {
      const data = franchiseeData?.recogData[docIndex];
      if (data) {
        return mapFieldEPLI(data);
      }
    }

    return {};
  }

  const [loading, setLoading] = useState(false);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

 const epliSections = [
   {
     title: "Effective Date",
     valueFn: () =>
       buildEffectDate(
         franchiseeData?.finalData?.epli_effective_date,
         franchisorData?.epli_required
       ),
     formValue: formatToMonthDayYear(franchiseeData?.finalData?.epli_effective_date)
   },
   {
     title: "Expiration Date",
     valueFn: () =>
       buildExpireDate(
         franchiseeData?.finalData?.epli_expire_date,
         franchisorData?.epli_required
       ),
     formValue: formatToMonthDayYear(franchiseeData?.finalData?.epli_expire_date)
   },
   {
     title: "Each Occurrence",
     valueFn: () =>
       buildValueGT(
         franchiseeData?.finalData?.epli_occ,
         franchisorData?.epli_occ,
         franchisorData?.epli_required
       ),
     fddRequirement: formatDollarAmount(franchisorData?.epli_occ),
     formValue: formatDollarAmount(franchiseeData?.finalData?.epli_occ),
   },
   {
     title: "Aggregate",
     valueFn: () =>
       buildValueGT(
         franchiseeData?.finalData?.epli_agg,
         franchisorData?.epli_agg,
         franchisorData?.epli_required
       ),
     fddRequirement: formatDollarAmount(franchisorData?.epli_agg),
     formValue: formatDollarAmount(franchiseeData?.finalData?.epli_agg),
   },
   {
     title: "Wage & Hour",
     valueFn: () =>
       buildValueGT(
         franchiseeData?.finalData?.epli_wage,
         franchisorData?.epli_wage,
         franchisorData?.epli_required
       ),
     fddRequirement: formatDollarAmount(franchisorData?.epli_wage),
     formValue: formatDollarAmount(franchiseeData?.finalData?.epli_wage),
   },
   {
     title: "Max Deductible",
     valueFn: () =>
       buildValueLT(
         franchiseeData?.finalData?.epli_mx,
         franchisorData?.epli_mx,
         franchisorData?.epli_required
       ),
     fddRequirement: formatDollarAmount(franchisorData?.epli_mx),
     formValue: formatDollarAmount(franchiseeData?.finalData?.epli_mx),
   },
   {
     title: "Third Party Coverage",
     valueFn: () =>
       buildEpliThirdParty(
         franchiseeData?.finalData?.epli_third_party,
         franchisorData?.epli_third_party,
         franchisorData?.epli_required
       ),
     fddRequirement: capitalize(franchisorData?.epli_third_party),
     formValue: capitalize(franchiseeData?.finalData?.epli_third_party),
   },
 ];

 const displayedSectionsCount = epliSections.filter(
   ({ title, valueFn, formValue }) => {
     const value = valueFn()?.[0]?.title;

     return (
       ((title !== "Effective Date" && title !== "Expiration Date") ||
         formValue ||
         (Array.isArray(valueFn?.()) && valueFn().length > 0)) &&
       (toggleState ? value : formValue || value)
     );
   }
 ).length;


  return (
    <div className="liability-table">
      {(() => {
        const hasInvalidExpirationDate = epliSections.some(
          ({ title, valueFn }) =>
            (title === "Expiration Date" || title === "Effective Date") &&
            (valueFn()?.[0]?.title === "Must expire after today's date" ||
              valueFn()?.[0]?.title === "Date required")
        );

        const effectiveToggleState = hasInvalidExpirationDate
          ? false
          : toggleState;

        return (
          (!effectiveToggleState || displayedSectionsCount > 0) && (
            <div>
              <h2>
                Employment Practices Liability #
                {franchiseeData?.finalData?.epli_policy_number}
              </h2>
              {hasInvalidExpirationDate && (
                <div className="coverage-lapsed-error">
                  <Alert
                    message="Please Upload COI to Renew Lapsed Coverage"
                    type="error"
                    showIcon
                  ></Alert>
                </div>
              )}
              <div className="table-header">
                <span className="table-header-field">Field</span>
                <span className="table-header-fdd">Requirement</span>
                <span className="table-header-coverage">Your Coverage</span>
              </div>

              {epliSections.map(
                ({ title, valueFn, fddRequirement, formValue }) => {
                  const buildValue = valueFn()?.[0]?.title;

                  const isCoverageLapsed = hasInvalidExpirationDate;

                  const isExpiringSoon =
                    title === "Expiration Date" &&
                    buildValue === "Expiring in 15 days or less";

                  const displayValue = buildValue || formValue;

                  const shouldDisplayDates =
                    title === "Effective Date" || title === "Expiration Date"
                      ? !effectiveToggleState ||
                        isCoverageLapsed ||
                        isExpiringSoon ||
                        buildValue
                      : effectiveToggleState
                      ? buildValue
                      : fddRequirement && fddRequirement !== "";

                  if (!shouldDisplayDates) {
                    return null;
                  }

                  return (
                    <div key={title} className="table-row">
                      <div className="table-cell field-title">
                        <span>{title}</span>
                      </div>
                      <div className="table-cell fdd-requirement">
                        <span>{fddRequirement || ""}</span>
                      </div>
                      <div className="table-cell">
                        {isCoverageLapsed ? (
                          <span className="value-red">
                            <WarningTwoTone twoToneColor={"#ff0000"} />{" "}
                            {typeof displayValue === "boolean"
                              ? displayValue
                                ? "Selected"
                                : "Not Selected"
                              : displayValue}
                          </span>
                        ) : effectiveToggleState && isExpiringSoon ? (
                          buildValue ? (
                            <span className="value-yellow">
                              <WarningTwoTone twoToneColor={"#ffcc00"} />{" "}
                              {buildValue}
                            </span>
                          ) : null
                        ) : effectiveToggleState ? (
                          <span className="value-red">
                            <WarningTwoTone twoToneColor={"#ff0000"} />{" "}
                            {buildValue}
                          </span>
                        ) : isExpiringSoon ? (
                          <span className="value-yellow">
                            <WarningTwoTone twoToneColor={"#ffcc00"} />{" "}
                            {buildValue}
                          </span>
                        ) : buildValue ? (
                          <span className="value-red">
                            <WarningTwoTone twoToneColor={"#ff0000"} />{" "}
                            {buildValue}
                          </span>
                        ) : (
                          <span className="value-green">
                            <CheckCircleTwoTone twoToneColor={"#46b946"} />{" "}
                            {typeof displayValue === "boolean"
                              ? displayValue
                                ? "Selected"
                                : null
                              : displayValue}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          )
        );
      })()}
    </div>
  );
}
