import React, { useState, useEffect } from 'react'
import { Modal, Form, Input as AntInput, Button, Switch, Select } from 'antd'
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { usePlacesWidget } from "react-google-autocomplete";

import { Input, Loading } from "@components";
import { getPrimaryContact } from '@utils/common';
import { getAddressItem } from "@utils/address";
import { extractLocationData } from '@utils/google-map';
import {
  REQUIREMENT_STATUS_DASHBOARD,
  REQUIREMENT_STATUS_ONBOARD
} from "@assets/const/status";
import { OTHER_HOST_LIST } from "@utils/host";
import { useAppState } from '@context/index'
import './style.scss'

export default function ZorAccInfoEdit({  isOpen, onClose, formVals, addressForm, setAddressForm, franchisorAdmin, handleChange, handleSave, handleChangeAddress, handleCampaignMode, handleOutReachStatus, handleActivate, handleDomainFranchizee, setLoading, domainPermission }) {

  const combinedObj = { ...formVals, ...addressForm, ...franchisorAdmin }
  const { authSetting } = useAppState();
  const { userRole } = authSetting;

  const [entityName, setEntityName] = useState(combinedObj.personal_infor_business_name || '');
  const [dba, setDba] = useState(combinedObj.dba || '');
  const [streetAddress, setStreetAddress] = useState(combinedObj.personal_infor_address || '');
  const [state, setState] = useState(combinedObj.personal_infor_state || '');
  const [city, setCity] = useState(combinedObj.personal_infor_city || '');
  const [zipCode, setZipCode] = useState(combinedObj.personal_infor_zip || '');
  const [logoUrl, setLogoUrl] = useState(combinedObj.personal_infor_logo || '');
  const [host, setHost] = useState(combinedObj.host || '');
  const [rmsInsVer, setRmsInsVer] = useState(combinedObj.rmsInsVer || false);
  const [enableRMSCompliance, setEnableRMSCompliance] = useState(combinedObj.enableRMSCompliance || false);
  const [outReachStatus, setOutReachStatus] = useState(combinedObj.outReachStatus || false);
  const [campaign_mode, setCampaignMode] = useState(combinedObj.campaign_mode || false);

  function resetFields() {
    setEntityName(combinedObj.personal_infor_business_name || '');
    setDba(combinedObj.dba || '');
    setStreetAddress(combinedObj.personal_infor_address || '');
    setState(combinedObj.personal_infor_state || '');
    setCity(combinedObj.personal_infor_city || '');
    setZipCode(combinedObj.personal_infor_zip || '');
    setLogoUrl(combinedObj.personal_infor_logo || '');
    setHost(combinedObj.host || '');
    setRmsInsVer(combinedObj.rmsInsVer || false);
    setEnableRMSCompliance(combinedObj.enableRMSCompliance || false);
    setOutReachStatus(combinedObj.outReachStatus || false);
    setCampaignMode(combinedObj.campaign_mode || false);
  }

  function closeModal() {
    resetFields();
    onClose();
  }

  function handleValidation() {
    if (!entityName || !dba || !streetAddress || !state || !city || !zipCode) {
      return false;
    }

    if (state.length !== 2) {
      return false;
    }

    if (zipCode.length > 10) {
      return false;
    }

    return true;
  }

  async function handleSubmit() {
    setLoading(true);

    if (handleValidation() === false) {
      setLoading(false);
      return;
    }

    const handleChangeData = {
      personal_infor_business_name: entityName,
      personal_infor_logo: logoUrl,
      dba,
      rmsInsVer,
      enableRMSCompliance,

    }
    const handleChangeAddressdata = {
      personal_infor_address: streetAddress,
      personal_infor_state: state,
      personal_infor_city: city,
      personal_infor_zip: zipCode,
    }

    Object.entries(handleChangeData).forEach(([key, value]) => {
      handleChange(key, value)
    });

    Object.entries(handleChangeAddressdata).forEach(([key, value]) => {
      handleChangeAddress(key.toString(), value)
    });

    onClose();
    if (host !== combinedObj.host || outReachStatus !== combinedObj.outReachStatus || campaign_mode !== combinedObj.campaign_mode) {
      if (host !== combinedObj.host) await handleDomainFranchizee(host)
      if (outReachStatus !== combinedObj.outReachStatus) await handleOutReachStatus(outReachStatus)
      if (campaign_mode !== combinedObj.campaign_mode) await handleCampaignMode(campaign_mode)
      setLoading(false);
    }

    await handleSave()

  }



  function zorButtons() {
    if (userRole === "ROLE_ADMIN") {
      return (
        <div className='zor-cr-btns'>
          <div className="activate_btn">
            <Switch
              size="large"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={rmsInsVer}
              onChange={(value) => setRmsInsVer(value)}
            />
            <label className="activate_btn-label">
              Policy Status Verification
            </label>
          </div>
          <div className="activate_btn">
            <Switch
              size="large"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={enableRMSCompliance}
              onChange={(value) =>
                setEnableRMSCompliance(value)
              }
            />
            <label className="activate_btn-label">
              Compliance Status Verification
            </label>
          </div>
          <div className="activate_btn">
            <Switch
              size="large"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={outReachStatus}
              onChange={(value) => setOutReachStatus(value)}
              // onChange={handleOutReachStatus}
            />
            <label className="activate_btn-label">Outreach Status</label>
          </div>
          <div className='activate_btn'>
            <Switch
              size="large"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={campaign_mode}
              onChange={(value) => setCampaignMode(value)}
              // onChange={handleCampaignMode}
            />
            <label className='activate_btn-label'>Campaign Mode</label>
          </div>
        </div>
      )
    }
  }

  return (
    <Modal
      title={<span className="modal-title">Edit Account Information</span>}
      className="poc-edit"
      open={isOpen}
      footer={null}
      onCancel={closeModal}
    >
      <Form>
        <div className='form-group-container'>
          <div>
            <label className='input-title'>Entity Name</label>
            <Form.Item
              rules={[
                { required: true, message: "Please input your entity name." }
              ]}
            >
              <AntInput
                required={true}
                placeholder="Entity Name"
                size="large"
                value={entityName}
                onChange={(evt) => setEntityName(evt.target.value)}
              />
            </Form.Item>
          </div>
          <div>
            <label className='input-title'>DBA</label>
            <Form.Item
              rules={[
                { required: true, message: "Please input your dba." }
              ]}
            >
              <AntInput
                required={true}
                placeholder="DBA"
                size="large"
                value={dba}
                onChange={(evt) => setDba(evt.target.value)}
              />
            </Form.Item>
          </div>
        </div>

        <div className='form-single-container'>
          <div>
            <label className='input-title'>Street Address</label>
            <Form.Item
              rules={[
                { required: true, message: "Please input your street address." }
              ]}
            >
              <GoogleMapsWrapper
                streetAddress={streetAddress}
                setStreetAddress={setStreetAddress}
                addressForm={addressForm}
                setAddressForm={setAddressForm}
                getAddressItem={getAddressItem}
                setState={setState}
                setCity={setCity}
                setZipCode={setZipCode}
              />
            </Form.Item>
          </div>
        </div>

        <div className='form-trio-container'>
          <div>
            <label className='input-title'>State</label>
            <Form.Item
              rules={[
                { required: true, message: "Please input your state." }
              ]}
            >
              <AntInput
                minLength={2}
                maxLength={2}
                required={true}
                placeholder="State"
                size="large"
                value={state}
                onChange={(evt) => setState(evt.target.value)}
              />
            </Form.Item>
          </div>
          <div>
            <label className='input-title'>City</label>
            <Form.Item
              rules={[
                { required: true, message: "Please input your city." }
              ]}
            >
              <AntInput
                required={true}
                placeholder="City"
                size="large"
                value={city}
                onChange={(evt) => setCity(evt.target.value)}
              />
            </Form.Item>
          </div>
          <div>
            <label className='input-title'>Zip Code</label>
            <Form.Item
              rules={[
                { required: true, message: "Please input your zip code." }
              ]}
            >
              <AntInput
                required={true}
                placeholder="Zip Code"
                size="large"
                value={zipCode}
                onChange={(evt) => setZipCode(evt.target.value)}
              />
            </Form.Item>
          </div>
        </div>
        <div className="form-single-container">
          <div>
            <label className='input-title'>Logo URL</label>
            <Form.Item
              rules={[
                { required: true, message: "Please input your logo url." }
              ]}
            >
              <AntInput
                required={true}
                placeholder="Logo URL"
                size="large"
                value={logoUrl}
                onChange={(evt) => setLogoUrl(evt.target.value)}
              />
            </Form.Item>
          </div>
        </div>
        {domainPermission && (
          <div className='form-group-container'>
            <div>
              <label className='input-title'>Host</label>
              <Form.Item
                rules={[
                  { required: true, message: "Please input your host." }
                ]}
              >
                <Select
                  placeholder="Select Host"
                  value={host}
                  size="large"
                  style={{ minWidth: "200px" }}
                  // onChange={(value) => handleDomainFranchizee(value)}
                  onChange={(value) => setHost(value)}
                  options={OTHER_HOST_LIST}
                />
              </Form.Item>
            </div>
          </div>
        )}
        {zorButtons()}
        <div className='poc-cr-btns'>
          <Button type="default" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" onClick={handleSubmit} >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

// wrapper component for Google Maps API.
function GoogleMapsWrapper({ streetAddress, setStreetAddress, addressForm, setAddressForm, getAddressItem, setState, setCity, setZipCode }) {
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    options: {
      types: ["address"],
    },
    onPlaceSelected: (place) => {
      if (place.address_components) {
        const { zip, city, state, street, route } = extractLocationData(
                  place.address_components
                );

        setStreetAddress(`${street} ${route}`)
        setState(state)
        setCity(city)
        setZipCode(zip)

        setAddressForm({
          ...addressForm,
          personal_infor_city: city,
          personal_infor_state: state,
          personal_infor_zip: zip,
          personal_infor_address: `${street} ${route}`,
        });
      }
    },
  });

  return (
    <input
      className='ant-input ant-input-lg css-dev-only-do-not-override-1okl62o ant-input-outlined'
      ref={ref}
      type='text'
      inputType="TYPE_INPUT_ADDRESS"
      required={true}
      placeholder="Street Address"
      size="large"
      value={streetAddress}
      onChange={(evt) => setStreetAddress(evt.target.value)}
    />
  )
}
