import React, { useEffect, useState } from "react";
import { Badge, Divider, Layout, Menu } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import logoRikor from "@assets/images/logo.svg";
import {
  UserOutlined,
  TeamOutlined,
  DotChartOutlined,
  FundOutlined,
  BugOutlined,
  HeartOutlined,
  BellOutlined,
  DashboardOutlined,
  SettingOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import { useAppState } from "@context";
import { userRole } from "@assets/const/auth";
import { APP_VERSION } from "@assets/const/version";
import logoAuthoritybrands from "@assets/images/logo-authoritybrands.png";
import logoHorsePowerBrands from "@assets/images/logo-horsepowerbrands.svg";
import logoHomerunfranchises from "@assets/images/logo-homerunfranchises.png";
import logoResibrands from "@assets/images/logo-resibrands.png";
import logoVeteranservicebrands from "@assets/images/logo-veteranservicebrands.png";
import logoHomeFrontsBrands from "@assets/images/logo-homefrontsbrand.png";
import logoFiveStarFranchisingBrand from "@assets/images/logo-five-star-franchising.png";
import logoEmpowerbrands from "@assets/images/logo-empowerbrands.png";
import logoThrivemorebrands from "@assets/images/logo-thrivemorebrands.png";
import logoFMFlow from "@assets/images/logo-fmflow.png";
import ReleaseNote from "@components/modals/release-notes";
import { post } from "@utils/axios";
import { useAuth0 } from "@auth0/auth0-react";
import "./style.scss";
import { getHostTag } from "@utils/host";

const { Sider } = Layout;

const getLogoImage = () => {
  switch (window.location.hostname) {
    case "app.authoritybrands.rikor.io":
      return logoAuthoritybrands;
    case "app.horsepowerbrands.rikor.io":
      return logoHorsePowerBrands;
    case "app.homefrontbrands.rikor.io":
      return logoHomeFrontsBrands;
    case "app.fivestarfranchising.rikor.io":
      return logoFiveStarFranchisingBrand;
    case "app.httbrands.rikor.io":
      return null;
    case "app.empowerbrands.rikor.io":
      return logoEmpowerbrands;
    case "app.veteranservicebrands.rikor.io":
      return logoVeteranservicebrands;
    case "app.thrivemorebrands.rikor.io":
      return logoThrivemorebrands;
    case "app.homerunfranchises.rikor.io":
      return logoHomerunfranchises;
    case "app.resibrands.rikor.io":
      return logoResibrands;
    case "app.fmflow.rikor.io":
      return logoFMFlow;
    default:
      return null;
  }
};

function getItem(label, key, icon, onClick, children) {
  return {
    key,
    icon,
    children,
    label,
    onClick
  };
}

export default function SideBar(props) {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const { authSetting, requirement, setNotifications } = useAppState();
  const [collapsed, setCollapsed] = useState(false);
  const [isReleaseNote, setIsReleaseNote] = useState(false);
  const [activeMenu, setActiveMenu] = useState([location.pathname]);
  const [tip, setTip] = useState(0);
  const logoImage = getLogoImage();

  const menuListAdmin = [
    getItem(
      "Real-Time Auditor™",
      "/admin/overview",
      <DotChartOutlined />,
      null
    ),
    getItem("Franchisors", "/admin/franchisors", <UserOutlined />, null),
    getItem("Franchisees", "/admin/franchisees", <TeamOutlined />, null),
    getItem("Report", "/admin/report", <FundOutlined />, null),
    getItem("Logs", "/admin/logs", <BugOutlined />, null),
    getItem(
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        Notifications {tip > 0 && <Badge size="small" count={tip}></Badge>}
      </div>,
      "/admin/notification",
      <BellOutlined />,
      null
    ),
    getItem("Settings", "/admin/settings", <TeamOutlined />, null, [
      getItem("Users", "/admin/settings/users", null, null),
      getItem("NCCI Codes", "/admin/settings/ncci-codes", null, null),
      getItem("Assignments", "/admin/settings/assignments", null, null),
      getItem("Duplicated Report", "/admin/settings/report", null, null),
      getItem("IR Templates", "/admin/settings/templates", null, null),
      getItem("Zor Checklist", "/admin/settings/checklist", null, null)
    ]),
    {
      type: "divider"
    },
    getItem("Affiliate Portal", "/affiliates/overview", <HeartOutlined />, null)
  ];
  const menuListFranchisor = [
    getItem("Overview", "/dashboard/overview", <DashboardOutlined />, null),
    getItem("Franchisees", "/dashboard/franchisees", <TeamOutlined />, null),
    getItem("Settings", "/dashboard/settings", <SettingOutlined />, null),
    getItem("Report", "/dashboard/report", <FundOutlined />, null),
    getItem(
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        Notifications {tip > 0 && <Badge size="small" count={tip}></Badge>}
      </div>,
      "/dashboard/notification",
      <BellOutlined />,
      null
    )
  ];
  const menuListFranchisee = [
    getItem("Account", "/board/account", <UserOutlined />, null)
  ];

  if (sessionStorage.getItem("redirect_url")) {
    menuListFranchisee.push(
      getItem(
        "Review & Save",
        "/board/schedule",
        <ExclamationCircleOutlined />,
        null
      )
    );
  }

  const menuList =
    authSetting.userRole === userRole.ROLE_ADMIN
      ? menuListAdmin
      : authSetting.userRole === userRole.ROLE_FRANCHISOR
      ? menuListFranchisor
      : menuListFranchisee;

  useEffect(() => {
    let activeMenuKey = null;
    const activeItem = menuList.find((item) => {
      if (item.children?.length > 0) {
        const activeChildItem = item.children.find((childItem) =>
          location.pathname?.includes(childItem.key)
        );
        if (activeChildItem) {
          activeMenuKey = activeChildItem.key;
          return true;
        }
      }

      if (location.pathname?.includes(item.key)) {
        activeMenuKey = item.key;
        return true;
      }

      return false;
    });
    setActiveMenu([activeMenuKey]);
  }, [location.pathname]);

  useEffect(() => {
    handleLoadNotification();
  }, [authSetting]);

  function handleActiveMenu(evt) {
    setActiveMenu([evt.key]);
    history.push(evt.key);
  }

  async function handleLoadNotification() {
    const { screen } = props;
    const idList = JSON.stringify(localStorage.getItem("notifications") || []);
    const token = await getAccessTokenSilently();

    if (screen === "admin") {
      const result = await post(
        `notification/query`,
        { requirementId: "admin", host: getHostTag() },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const data = result?.data?.data || [];
      const unreadData = data.filter((item) => !idList.includes(item._id));
      setNotifications(data);
      setTip(unreadData.length);
    } else if (screen === "dashboard" && requirement?._id) {
      const result = await post(
        `notification/query`,
        { requirementId: requirement._id, host: getHostTag() },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const data = result?.data?.data || [];
      const unreadData = data.filter((item) => !idList.includes(item._id));

      setNotifications(data);
      setTip(unreadData.length);
    }
  }

  return (
    <Sider
      className="sidebar"
      theme="light"
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div className="sidebar-logo">
        <img src={logoRikor} className="sidebar-logo-image" />
        <div className="sidebar-logo-version">
          <span
            className="sidebar-logo-version-label"
            onClick={() => setIsReleaseNote(true)}
          >
            v.{APP_VERSION}
          </span>
        </div>
      </div>
      <Divider />
      {logoImage && (
        <div className="sidebar-sublogo">
          <img src={logoImage} className="sidebar-sublogo-img" />
        </div>
      )}
      <Menu
        theme="light"
        selectedKeys={activeMenu}
        onSelect={handleActiveMenu}
        mode="inline"
        items={menuList}
      />
      <ReleaseNote
        isOpen={isReleaseNote}
        onClose={() => setIsReleaseNote(false)}
      />
    </Sider>
  );
}
