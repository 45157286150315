import React, { useEffect, useState } from "react";
import { Button, Modal, notification, Form, Alert, Space } from "antd";
import { UserAddOutlined, SendOutlined, LinkOutlined } from "@ant-design/icons";
import ContactItem from "./contact-item";
import "./style.scss";
import { Loading, Card } from "@components";
import { useAppState } from "@context";
import { useAuth0 } from "@auth0/auth0-react";
import { post, put } from "@utils/axios";
import ZorPOCForm from "@components/zor-poc";
import { USER_TYPE_FRANCHISOR } from "@assets/const/status";
import { getHostTag } from "@utils/host";
import { ERROR_TYPE_CONTINUE, ERROR_TYPE_QUIT } from "@assets/const/form";
import { AUTH0_CONNECTION_USER_PASSWORD, AUTH0_CONNECTION_EMAIL } from "@assets/const/auth";

export default function RealTimeNotifications(props) {
  const { getAccessTokenSilently, user } = useAuth0();
  const { franchisorAdmin, setFranchisorAdmin, authSetting } = useAppState();
  const [contactList, setContactList] = useState(
    franchisorAdmin?.poc_contacts || []
  );
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState();
  const [formValues, setFormValues] = useState();
  const [zorForm] = Form.useForm();

  useEffect(() => {
    setContactList(franchisorAdmin?.poc_contacts || []);
  }, [franchisorAdmin?.poc_contacts]);

  function handleAdd() {
    setIsOpen(true);
  }

  function handleChange(index, key, value) {
    contactList[index][key] = value;
    setContactList([...contactList]);
  }

  function handleClose(index) {
    contactList.splice(index, 1);
    setContactList([...contactList]);
  }

  async function handleSave() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      if (franchisorAdmin?._id) {
        const result = await put(
          `requirement/${franchisorAdmin._id}`,
          { poc_contacts: contactList },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (result.data.success) {
          const requirement = result.data.data;
          setFranchisorAdmin({ ...franchisorAdmin, ...requirement });
        }
      } else {
        const result = await post(
          `requirement`,
          { zee_rating_basis: contactList },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (result.data.success) {
          const requirement = result.data.data;
          setFranchisorAdmin({ ...franchisorAdmin, ...requirement });
        }
      }
      setLoading(false);
    } catch (e) {
      handleNotification(
        "Failed",
        "Save form is failed. Please contact to admin directly."
      );
      setLoading(false);
    }
  }

  function handleNotification(title, description) {
    notification["error"]({
      message: title,
      description: description,
      duration: 3
    });
  }

  async function handleAddPOC(values) {
    setLoadingModal(true);
    setError(null);
    const token = await getAccessTokenSilently();

    try {
      // "regUser"
      const result = await post(
        `admin/user/query`,
        { email: values.email, requiredType: USER_TYPE_FRANCHISOR },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (result?.data?.errorStatus) {
        setError({
          type: result?.data?.errorStatus,
          msg: result?.data?.msg
        });
        setFormValues({ ...result?.data?.data, telephone: values.telephone });
        setLoadingModal(false);
        return;
      }
    } catch (error) {}

    await handleCreateContact(values);
    setLoadingModal(false);
  }

  async function handleOverWrite() {
    setLoadingModal(true);
    try {
      const token = await getAccessTokenSilently();
      await put(
        `user/${formValues._id}`,
        {
          requirementId: franchisorAdmin?._id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      contactList.push({
        firstName: formValues.first_name,
        lastName: formValues.last_name,
        email: formValues.email,
        telephone: formValues.telephone,
        directLine: formValues.directLine,
        preferredMethod: formValues.preferredMethod,
        role: formValues.role,
        isPrimary: formValues.isPrimary,
        isActive: formValues.isActive
      });
      const result = await put(
        `requirement/${franchisorAdmin._id}`,
        { poc_contacts: contactList },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (result.data.success) {
        const requirement = result.data.data;
        setFranchisorAdmin({ ...franchisorAdmin, ...requirement });
      }
      zorForm.resetFields();
      setIsOpen(false);
      setError(null);
      setFormValues(null);
    } catch (error) {}

    setLoadingModal(false);
  }

  async function handleCreateContact(values) {
    try {
      const token = await getAccessTokenSilently();
      await post(
        `admin/user/create`,
        {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          password: values.password,
          name: `${values.firstName} ${values.lastName}`,
          type: USER_TYPE_FRANCHISOR,
          permission: "",
          host: getHostTag(),
          requirementId: franchisorAdmin?._id,
          createdBy: user?.email,
          connection: AUTH0_CONNECTION_EMAIL
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      contactList.push({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        telephone: values.telephone,
        directLine: values.directLine,
        preferredMethod: values.preferredMethod,
        role: values.role,
        isPrimary: values.isPrimary,
        isActive: values.isActive,
        createdBy: user?.email,
        createdAt: new Date()
      });
      const result = await put(
        `requirement/${franchisorAdmin._id}`,
        { poc_contacts: contactList },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (result.data.success) {
        const requirement = result.data.data;
        setFranchisorAdmin({ ...franchisorAdmin, ...requirement });
      }
      zorForm.resetFields();
      setIsOpen(false);
    } catch (error) {
      console.log("error ---", error);
      handleNotification(
        "Failed",
        "Unable to create POC Contact now. Please contact admin directly."
      );
    }
  }

  function renderModal() {
    return (
      <Modal
        title="Add User Contact"
        open={isOpen}
        onOk={() => zorForm.submit()}
        onCancel={() => setIsOpen(false)}
        footer={[
          <Button size="large" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>,
          <Button
            size="large"
            type="primary"
            onClick={() => zorForm.submit()}
            loading={loadingModal}
          >
            Add
          </Button>
        ]}
      >
        {error && (
          <div>
            {error.type === ERROR_TYPE_CONTINUE && (
              <Alert
                style={{ marginBottom: "20px" }}
                message={error.msg}
                type="info"
                action={
                  <Space>
                    <Button
                      icon={<LinkOutlined />}
                      type="primary"
                      loading={loadingModal}
                      size="small"
                      onClick={handleOverWrite}
                    >
                      Link User
                    </Button>
                  </Space>
                }
              />
            )}
            {error.type === ERROR_TYPE_QUIT && (
              <Alert
                style={{ marginBottom: "20px" }}
                message={error.msg}
                type="error"
              />
            )}
          </div>
        )}
        <ZorPOCForm form={zorForm} onSubmit={handleAddPOC} />
      </Modal>
    );
  }

  return (
    <div className="real">
      <Card
        title="Users"
        extra={() => (
          <div className="real-header-action">
            <Button onClick={handleAdd} type="primary">
              <UserAddOutlined /> Add User
            </Button>

            {props.visibleReportBtn && (
              <Button
                className="in_per-account-content-footer-btn-email"
                type="secondary"
                onClick={props.onNotifyEmail}
              >
                <SendOutlined /> Send Report Email
              </Button>
            )}
          </div>
        )}
      >
        <div className="real-main">
          {contactList.map((contactItem, index) => (
            <ContactItem
              handleSave={handleSave}
              data={contactItem}
              onChange={(key, value) => handleChange(index, key, value)}
              onClose={() => handleClose(index)}
            />
          ))}
        </div>
        {/* <div className="real-footer">
          <Button onClick={handleSave} type="primary" size="large">
            Save
          </Button>
        </div> */}
        <Loading loading={loading} />
      </Card>
      {renderModal()}
    </div>
  );
}
